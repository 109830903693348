import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'linkifyjs/react';

import { openTags } from 'shared/functions';

import aiui from 'containers/AIUI';

class AIMessage extends React.PureComponent<any, any> {
  UNSAFE_componentWillMount() {
    this.createElements();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { messages, index, acceptExtensions } = nextProps;

    const nextIndex = index + 1;
    const answer = messages.length > nextIndex ? messages[nextIndex].content : '';

    if ([answer !== this.state.answer, acceptExtensions !== this.props.acceptExtensions].some(Boolean)) {
      this.createElements(nextProps);
    }
  }

  componentDidUpdate() {
    if (this.props.editable) {
      this.props.delayScrollDown();
    }
  }

  createElements(props = this.props) {
    const { messages, index, acceptExtensions, content, editable, bottomMode, controlsOnly, delayScrollDown, options } =
      props;

    const nextIndex = index + 1;
    const answer = messages.length > nextIndex ? messages[nextIndex].content : '';
    const div = document.createElement('div');
    div.innerHTML = openTags(content);

    // @ts-expect-error
    const elements = [...div.childNodes]
      .map((element, key) => {
        if (element.nodeName === '#text') {
          return React.createElement('span', { key }, element.nodeValue);
        } else if (element.nodeName === 'IMG') {
          return (
            <div
              key={key}
              dangerouslySetInnerHTML={{ __html: element.outerHTML }}
              style={{
                width: '100%',
              }}
            />
          );
        } else if (!aiui[`${element.nodeName}`.toLowerCase()]) {
          return <span key={key} dangerouslySetInnerHTML={{ __html: element.outerHTML }} />;
        }

        const attributes = [...element.attributes].reduce((prev, cur) => {
          prev[cur.localName] = cur.nodeValue;
          return prev;
        }, {});
        if (answer && aiui[element.localName]) {
          return null;
        }

        const aiuiProps = aiui[element.localName]
          ? {
              attributes,
              editable,
              resultingContent: this.aiResultingContent.bind(this),
              answer,
              content,
              acceptExtensions,
              delayScrollDown,
              isSkippable: options?.isSkippable || false,
            }
          : {};

        return React.createElement(aiui[element.localName] ? aiui[element.localName] : element.localName, {
          key,
          ...aiuiProps,
        });
      })
      .filter((item) => item && (!bottomMode || (typeof item.type === 'function') === !!controlsOnly));
    this.setState({ elements, answer });
  }

  aiResultingContent(content, file, fileUrl) {
    this.props.emitSendMessage(content, file, fileUrl);
  }

  render() {
    const { elements } = this.state;

    return <Linkify>{elements}</Linkify>;
  }
}

// @ts-expect-error
AIMessage.propTypes = {
  messages: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  bottomMode: PropTypes.bool,
  controlsOnly: PropTypes.bool,
  emitSendMessage: PropTypes.func.isRequired,
  delayScrollDown: PropTypes.func.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default AIMessage;
